import React from "react";
import Slider8 from "../slider/Slider8";

function Footer2() {
  return (
    <div>
      <Slider8 />
    </div>
  );
}

export default Footer2;
