import React, { useState } from "react";
import Styles from "./Styles.module.css";
import { Zoom } from "react-awesome-reveal";
import { Link } from "react-router-dom";
import {
  Facebook,
  Instagram,
  LinkedIn,
  WhatsApp,
} from "@mui/icons-material";
import {} from "react-icons/fa";

const Slider8 = () => {


  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 100) {
      setVisible(true);
    } else if (scrolled <= 100) {
      setVisible(false);
    }
  };



  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour 
      in place of 'smooth' */
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <div
      className={Styles.slider8}
      style={{ backgroundColor: "var(--stargreen)!important" }}
    >
      <div className={Styles.mainContainer}>
        <div className={Styles.container8}>
          <div className={Styles.productCard}>
            <Zoom>
              <div className={Styles.footerHighlight}>
                <h3>
                  We work with rural communities in Kenya, to build their
                  capacity, alleviate poverty and achieve social justice.
                </h3>
                <Link to="/">
                  <img src="/aboutus/footerlogo.png" alt="Curd Africa" />
                </Link>
              </div>
            </Zoom>
          </div>
          <div className={Styles.productCard}>
            <h4>Quick Links</h4>
            {navLinks.map((menulink, index) => (
              <li key={index}>
                <Link
                  style={{
                    paddingTopTop: "-2rem",
                    display: visible ? "inline" : "none",
                  }}
                  onClick={scrollToTop}
                  to={menulink.path}
                >
                  {" "}
                  * {menulink.title}
                </Link>{" "}
              </li>
            ))}
          </div>
          <div className={Styles.productCard}>
            <h4>Key Links</h4>
            {generalLinks.map((menulink, index) => (
              <li key={index}>
                <Link
                  style={{
                    paddingTopTop: "-2rem",
                    display: visible ? "inline" : "none",
                  }}
                  onClick={scrollToTop}
                  to={menulink.path}
                >
                  {" "}
                  * {menulink.title}
                </Link>{" "}
              </li>
            ))}
          </div>
        </div>

        <div className={Styles.footer}>
          <p>
            Copyright : &copy; {new Date().getFullYear()} COMMUNITY UNITED FOR
            RURAL DEVELOPMENT. All Rights Reserved
          </p>
          <div className={Styles.socials}>
            <span>
              <Facebook />
            </span>
            <span>
              <WhatsApp />
            </span>
            <span>
              <Instagram />
            </span>
            <span>
              <LinkedIn />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider8;

const navLinks = [
  { title: "Home", path: "/" },
  {
    title: "Governance and Democracy ",
    path: "/Governance and Democracy ",
  },
  {
    title: "Economic empowerment and Climate Change",
    path: "/Economic empowerment and Climate Change",
  },
  {
    title: "Social Justice Inclusion and Child Protection",
    path: "/Social Justice Inclusion and Child Protection",
  },
  {
    title: "HIV AIDS Drug and Drug Abuse",
    path: "/HIV AIDS Drug and Drug Abuse",
  },
  {
    title: "Community Resource Center and Medical Clinic",
    path: "/Community Resource Center and Medical Clinic",
  },
  {
    title: "Institutional Capacity Strengthening",
    path: "/Institutional Capacity Strengthening",
  },
];

const generalLinks = [
  { title: "Careers", path: "/Careers" },
  { title: "Sitemap", path: "/Site Map" },
  { title: "Frequently Asked Questions", path: "/Frequently Asked Questions" },
  { title: "Home", path: "/" },
  { title: "Privacy Policy", path: "/Privacy Policy" },
  { title: "Terms of Service", path: "/Terms Of Service" },
  {
    title: "Donate: ",
    path: "/Donate",
  },
  { title: "Contact Us", path: "/Contact Us" },
];
