import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Styles from "./styles1.module.css";
import { ShowCases } from "./showcaseData";
import SkeletonVariant from "../../hooks/SkeletonVariant";

function ShowCase() {
  const [activity, setActivity] = useState([]);
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    const fetchData = async () => {
      try {
        await delay(2000);
        setActivity(ShowCases);
      } catch (error) {
        console.error("Error fetching themes:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className={Styles.themesContainer}>
      {!activity[0] ? (
        <>
          {data.map((_, index) => (
            <div key={index}>
              <SkeletonVariant />
            </div>
          ))}
        </>
      ) : (
        activity.map((showcase, index) => (
          <div className={Styles.productCard} key={index}>
            <Link to={showcase.link}>
              <h5>{showcase.title}</h5>
              <img
                src={showcase.image}
                alt="Community United for Rural Development"
              />{" "}
              <p>
                {showcase.description.substring(0, 150)} .......{">>"}
              </p>
              <div>
                <button>Read More</button>
              </div>{" "}
            </Link>
          </div>
        ))
      )}
    </div>
  );
}

export default ShowCase;
const data = [1, 2, 3, 4];
