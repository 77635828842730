import React from "react";
import Styles from "./Styles.module.css";
import { Link } from "react-router-dom";
import { Zoom } from "react-awesome-reveal";
import SkeletonDisplay from "../../hooks/skeletonDisplay";

const Slider3 = () => {
  return (
    <div className={Styles.slider3}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <Zoom>
              <h1>The Community United for Rural Development (CURD)</h1>
              <p>
                The Community United for Rural Development (CURD) is Kenyan,
                women-led non-profit, Non-Governmental Organization (NGO). Our
                main Vision Empowered and Poverty Free Communities. Our focus is
                to work with communities, national and county government,
                religious leaders and opinion leaders. We seek to increase,
                participation of communities in management of their welfare. Our
                focus in the next five years 2024-2029 will include
              </p>
              <div className={Styles.productCard}>
                <h3>Thematic Areas</h3>
                {list.map((item, index) => (
                  <li key={index}>
                    <Link to={`/${item}`}>{item}</Link>
                  </li>
                ))}
              </div>
            </Zoom>
          </div>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/aboutus/governance-and-democracy-curd.jpg"
                alt="Curd Africa"
              />
            </SkeletonDisplay>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider3;

const list = [
  "Governance and Democracy",
  "Economic empowerment and Climate Change",
  "Social Justice Inclusion and Child Protection",
  "HIV AIDS Drug and Drug Abuse",
  "Community Resource Center and Medical Clinic",
  "Institutional Capacity Strengthening",
];
