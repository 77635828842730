// NavBarData.js
const navLinks = [
  { title: "Home", path: "/" },
  {
    title: "About us",
    path: "/About Us",
  },
  {
    title: "CURD in Action",
    path: "/",
    subLinks: [
      "Governance and Democracy",
      "Economic empowerment and Climate Change",
      "Social Justice Inclusion and Child Protection",
      "HIV AIDS Drug and Drug Abuse",
      "Community Resource Center and Medical Clinic",
      "Institutional Capacity Strengthening",
    ],
  },
  {
    title: "News & Views",
    path: "News & Views",
    subLinks: ["In the News", "Blog", "Press Release", "Publications"],
  },
  {
    title: "Get Involved",
    path: "/Get Involved",
    subLinks: ["Donate", "Volunteers", "Careers"],
  },
  { title: "Contact us", path: "/Contact Us" },
];

export default navLinks;
