import React from "react";
import Styles from "./Styles.module.css";
import SkeletonDisplay from "../../hooks/skeletonDisplay";

const Slider5 = () => {
  return (
    <div className={Styles.slider3}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <h1> Social Justice, Inclusion and Child Protection</h1>
            <p>
              The Constitution of Kenya , 2010, provides a solid legal and
              institutional framework for the recognition and protection of the
              rights of minorities and those of marginalised groups. It does
              this through its rights-based approach to development, where
              everyone is entitled to development as a right. Additionally, 2010
              Constitution of Kenya (Article 53) recognises the right of all
              children to be protected from abuse, neglect, harmful cultural
              practices, all forms of violence, inhumane treatment and
              punishment, and hazardous or exploitative labour
            </p>
          </div>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/aboutus/social-Justice-inclusion-and-child-protection-Curd-africa.jpg"
                alt="Curd Africa"
              />
            </SkeletonDisplay>
          </div>
        </div>
        <div>
          <p>
            According to the UNICEF report, In Kenya, the Government has
            developed and adopted policies and enacted laws to protect children.
            However, thousands are still exposed to violence and abuse, harmful
            practices, lack of parental care and sexual exploitation. Children
            also face specific risks during and after emergencies such as
            floods, droughts, conflicts or epidemics. An emerging threat for
            children is online abuse and exploitation. Around one in two young
            adults in Kenya experienced violence as a child. The prevalence of
            child marriage among girls stands at 23 per cent
          </p>
        </div>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/aboutus/economic-empowerment-and-climate-change-africa-1.jpg"
                alt="Curd Africa"
              />
            </SkeletonDisplay>
          </div>
          <div className={Styles.productCard}>
            <h3>Thematic Key Activities</h3>
            {list.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider5;

const list = [
"Training of Organisation of Persons with Disabilities(OPDs)",
"Provision of Assistive Devices",
"Undertaking Studies on Inclusion",
"Provision of grants to OPDs",
"Advocacy and awareness",
"Social Protection",
"Children's Care and Support",
"Sexual Gender Based Violence"
];
