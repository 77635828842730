import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { KeyboardArrowRight } from "@mui/icons-material";

export default function AccordianComponent() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      {details.map((item, index) => (
        <Accordion
          expanded={expanded === item.id}
          onChange={handleChange(item.id)}
          key={index}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
                  <Typography sx={{ width: "100%", flexShrink: 0 }} style={{fontWeight:"bold"}}>
              {item.title}
            </Typography>
          </AccordionSummary>
          {item.description.map((subitem, index2) => (
            <AccordionDetails key={index2}>
              <Typography>
                {" "}
                <KeyboardArrowRight style={{margin:"-0.5rem", paddingRight:"1.5rem"}}/>
                {subitem}
              </Typography>
            </AccordionDetails>
          ))}
        </Accordion>
      ))}
    </div>
  );
}

const details = [
  {
    id: "panel1",
    title: "Thematic one (1): Governance and Democracy",
    description: [
      "Developing and institutionalizing a community-level user social Accountability monitoring system in the targeted sectors of  health, water and agriculture services targeted counties.",
      "Setting up internal systems for gathering and storing data on counties services access and use in the targeted counties.",
      "Establish procedures and mechanism for participation by women, persons with disabilities and youth in the county governance systems.",
      "Training and mentoring  500 Trainers of Trainees in targeted counties on County Budgeting Process, Civic Education, Public Participation, Gender Inclusion, with focus on Women, Youth and Person with Disabilities, in Health, water and Agriculture Sectors.",
      "Training of 500 civic educators in election observation, political parties’ participation and election processes and procedures.",
    ],
  },
  {
    id: "panel2",
    title: "Thematic two (2): Economic empowerment and Climate Change.",
    description: [
      "Train groups and individuals on wealth generation and poverty reduction, including smart climate farming, Poultry farming, pig farming etc",
      "Mobilize individuals, schools and community organizations, private companies, churches, special interest groups and development partners to contribute and support periodic planting Two Million in the targeted counties.",
      "Conduct outreaches and reach 1000,000 community members through sensitization of appropriate means, training to communities, groups schools, universities churches, mosques, persons with disabilities on the effect of climate change and importance of planting tree.",
      "Establishing and strengthening 200 climate change clubs in Secondary, primary school and groups of schools to sustain the initiative of planting trees in Nairobi County Kenya.",
      "Provide an opportunity for private sector and development partners to contribute resources and participate in the ongoing 15 billion Tree Growing Strategy 2023-2032: Special Presidential Forestry Programme",
    ],
  },
  {
    id: "panel3",
    title: "Thematic three (3): Social Justice, Inclusion and Child Protection",
    description: [
      "Conduct sensitization and advocacy programmes at community levels for behaviour change in perceptions on women’s, children and persons with disabilities status",
      "Train community groups on organizing social movements",
      "Hold public dialogue forums at community and national levels on social inclusion",
      "Build capacity of community groups and institutions to provide care and support to OVCs",
      "Empower community, government and religious leaders to combat child labor in the targeted counties",
    ],
  },
  {
    id: "panel4",
    title: "Thematic four (4): Focusing on HIV/AIDS and Drug and drug abuse.",
    description: [
      "Carry out sexual reproductive health and rights activities among young people between ages 10-24 in and out of school in the counties",
      "Conduct behavior change communication education for households and the general public in the counties.",
    ],
  },
  {
    id: "panel5",
    title:
      "Thematic Five(5): Designing and Establishment One Stop Shop Resource Center/ Medical Clinic in Ematali Village, Kakamega County, Kenya .",
    description: [
      "Provide funding to buy off land that has been identified and provided by the Emutali community for development of One Stop Shop Resource and Establishment Community Medical Center.",
      "Put up a One-Stop-Shop Community Resource Centre that will house a community medical Centre, a youth-friendly center, community hall, vocational center, and a community library",
      "Provide support in equipping the vocational training and youth-friendly center starting with a computer, tailoring session where community women, girls and men can come and learn various trade.",
      "Provide small support to support a few personnel in the library, hospital and vocational training center.",
    ],
  },
  {
    id: "panel6",
    title: "Thematic Six  (6 ): CURD Institutional Capacity Strengthening",
    description: [
      "Provide technical support review, develop, print and disseminate of strategic and fundraising Plan 2024-2029.",
      "Provide Technical Support, in development of Institutional Policies Policies.",
      "Train and mentor the Board of Directors.",
    ],
  },
];
