import React from "react";
import Styles from "./Styles.module.css";
import SkeletonDisplay from "../../hooks/skeletonDisplay";

const Slider7 = () => {
  return (
    <div className={Styles.slider3}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/aboutus/community-resource-and-medical-center.jpg"
                alt="Curd Africa"
              />
            </SkeletonDisplay>
          </div>
          <div className={Styles.productCard}>
            <h1>
              {" "}
              One Stop Shop Resource and Medical Clinic in Mundeku, Kakamega
              County, Kenya
            </h1>
            <p>
              Access to essential services is more challenging in rural areas of
              Khwisero Sub-county in Kakamega County, with lower service
              capacity and without a critical mass of key workers. The impact of
              HIV and AIDS can not under underestimated, with over 2.6 children
              with the pandemic and with the situation worsened by the COVID-19
              Pandemic. Rural areas are heavily populated with old grandmothers
              and fathers who are left with the burden of taking care of the
              children. The children face challenges in their early education
              leading to poor education results.
            </p>
          </div>
        </div>
        <div>
          <p>
            The Government of Kenya takes cognizance of the crucial role of
            Technical and Vocational Education and Training (TVET) in the
            provision of skills and competencies for industrialization, lifelong
            learning and national development. This is in line with the
            Sustainable Development Goal (SDG) goal 4 which obligates States to
            ensure a quality education to their youth and adults by providing
            relevant, technical and vocational skills, for employment, decent
            jobs and entrepreneurship. SDG goal 8 further calls on states to
            “Promote sustained, inclusive and sustainable economic growth, full
            and productive employment and decent work for all.” There have been
            attempts to provide develop vocational training centres, but they
            remain ill-equipped and dont provide holistic training to youth.
          </p>
          <p>
            We propose to Fundraise, Design, Establish, and Equip rural-based
            Mundeku One Stop Shop Community Resource and Medical Center. The
            community has contributed the land to the project, which should be
            bought off at the community rate. The centre will have the following
          </p>
        </div>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/aboutus/community-resource-and-medical-center-1.jpg"
                alt="Curd Africa"
              />
            </SkeletonDisplay>
          </div>
          <div className={Styles.productCard}>
            <h3>Thematic Key Activities</h3>
            {list.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider7;

const list = [
  "Community Medical Center",
  "Youth Friendly Center",
  "Vocational Center",
  "Early Childhood Development Center(ECD)",
  "Community Social Hall",
  "Water Point",
  "Playing Field, Basket Ball, Netball, Valley Ball and Tennis Court.",
];
