import React from "react";
import Styles from "./styles.module.css";
import { Link } from "react-router-dom";
import { AttentionSeeker, Zoom } from "react-awesome-reveal";

function MainMenu() {
  return (
    <div className={Styles.Navbar}>
      <div className={Styles.rightSide}>
        <Link to="/">
          <img src="aboutus/logo.png" alt="Curd Africa" />
        </Link>
      </div>
      <div className={Styles.leftSide}>
        <AttentionSeeker>
          <Link to="/">
            <h1>COMMUNITY UNITED FOR RURAL DEVELOPMENT </h1>
          </Link>
        </AttentionSeeker>
        <Zoom>
          <p>
            We work with rural communities in Kenya, to build their capacity,
            alleviate poverty and achieve social justice.
          </p>
        </Zoom>
      </div>
    </div>
  );
}

export default MainMenu;
