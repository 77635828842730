import React from "react";

import Header from "./components/header/header";
import ShowCase from "./components/slider/showcase/showcase";

function Home() {
  return (
    <div>
      <Header />
      <ShowCase />
    </div>
  );
}

export default Home;
