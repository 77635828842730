import React, { useState, useEffect } from "react";
import SkeletonVariant from "./SkeletonVariant";

const SkeletonDisplay = ({ children }) => {
  const [activity, setActivity] = useState([]);
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    const fetchData = async () => {
      try {
        await delay(3000);
        setActivity([1]);
      } catch (error) {
        console.error("Error fetching themes:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <div>
      {!activity[0] ? (
        <>
          {data.map((_, index) => (
            <div key={index}>
              <SkeletonVariant />
            </div>
          ))}
        </>
      ) : (
        activity.map((_, index) => <div key={index}>{children}</div>)
      )}
    </div>
  );
};

export default SkeletonDisplay;

const data = [1];
