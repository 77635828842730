import React from "react";
import { Outlet } from "react-router-dom";
import NavBar from "../navbar/navbar";
import Footer2 from "../footer/footer2";

function Layout2() {
  return (
    <div>
      <NavBar />
      <div>
        <Outlet />
      </div>
      <Footer2 />
    </div>
  );
}

export default Layout2;
