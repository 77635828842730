import React from "react";
import Styles from "./Styles.module.css";

import SkeletonDisplay from "../../hooks/skeletonDisplay";

const Slider4 = () => {
  return (
    <div className={Styles.slider3}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/aboutus/economic-empowerment-and-climate-change-africa.jpg"
                alt="Curd Africa"
              />
            </SkeletonDisplay>
          </div>
          <div className={Styles.productCard}>
            <h1>Economic empowerment and Climate Change</h1>
            <p>
              About one-half of Kenya’s population lives below the poverty line
              majority being in rural areas. Kenya is one of the 10 most unequal
              countries in the world and the five most unequal in Africa. Major
              factors responsible for the erosion of rural livelihoods in Kenya
              are; The pressure on natural resources and particularly access to
              land; widespread decline or collapse of institutions and services;
              and the depletion of human capital especially due to the HIV and
              AIDS pandemic.
            </p>
          </div>
        </div>
        <div>
          <p>
            Kenya is a country blessed with diverse natural resources, a rich
            cultural heritage, and immense potential for development. However,
            the country is also facing serious environmental challenges, such as
            deforestation, land degradation, and drought, that are being
            accelerated by climate change. These challenges threaten the
            livelihoods of millions of Kenyans, especially the rural poor who
            depend on natural resources for their survival.Kenyan launched 10
            year ambitious and visionary program to plant 15 billion tree by
            2032
          </p>
          <p>
            Unemployment especially among the youth is still very high. Most of
            the youth have no vocational or professional skills making even
            self-employment among them difficult. Owing to acute poverty and
            lack of employment, crime, especially among the youth is on the
            rise.Women are comparatively also affected by unemployment as they
            have assumed the role of family providers in many households. Rising
            commodity prices and lack of employment among Kenya’s majority
            population and therefore the inability to access human basic needs
            has contributed significantly to rising poverty levels reversing the
            gains in economic growth.
          </p>
          <p>
            Effective poverty and inequality reduction in Kenya is a very
            challenging task that requires a systematic and integrated approach.
            To successfully face this challenge requires substantial and
            sustained efforts by the government and Kenya’s development
            partners. Improving economic growth rates, though necessary, are not
            always sufficient to reduce poverty.
          </p>
        </div>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/aboutus/economic-empowerment-and-climate-change-africa-1.jpg"
                alt="Curd Africa"
              />
            </SkeletonDisplay>
          </div>
          <div className={Styles.productCard}>
            <h3>Thematic Key Activities</h3>
            {list.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider4;

const list = [
"Planting of trees in public spaces and homes", 
"Environmental Conservation",
"Agro-Forestry", 
"Green Energy", 
"Sustainable Livelihood",
"Awareness of Environmental Sustainability", 
"Provision of climate change grants to community groups"
];
