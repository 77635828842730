import React from "react";
import Styles from "./Styles.module.css";
import Slider3 from "./programsliders/Slider3";
import SkeletonDisplay from "../hooks/skeletonDisplay";

function GovernanceandDemocracy() {
  return (
    <div>
      <div className={Styles.slider1}>
        <div className={Styles.mainContainer}>
          <SkeletonDisplay>
            <img src="/aboutus/aboutus.png" alt="Curd Africa" />
          </SkeletonDisplay>
          <button>Governance and Democracy</button>
        </div>
      </div>
      <div style={{ margin: "0 auto" }}>
        <Slider3 />
      </div>
    </div>
  );
}

export default GovernanceandDemocracy;
