import React from "react";
import Styles from "./Styles.module.css";
import SkeletonDisplay from "../../hooks/skeletonDisplay";

const Slider8 = () => {
  return (
    <div className={Styles.slider3}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <h1>
              {" "}
              CURD Institutional Capacity Strengthening, Fundraising and
              Partnership
            </h1>
            <p>
              With a broad network of local, stakeholders, church partners and
              deep roots in the communities where we work, CURD is uniquely
              positioned to assist with community capacity building. We
              strengthen local organizations, support grassroots advocacy, and
              provide strategic guidance that leads to sustainable change.
            </p>
          </div>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/aboutus/institutional-capacity-strengthening-fundraising-and-partnership.jpg"
                alt="Curd Africa"
              />
            </SkeletonDisplay>
          </div>
        </div>
        <div>
          <p>
            The Government of Kenya takes cognizance of the crucial role of
            Technical and Vocational Education and Training (TVET) in the
            provision of skills and competencies for industrialization, lifelong
            learning and national development. This is in line with the
            Sustainable Development Goal (SDG) goal 4 which obligates States to
            ensure a quality education to their youth and adults by providing
            relevant, technical and vocational skills, for employment, decent
            jobs and entrepreneurship. SDG goal 8 further calls on states to
            “Promote sustained, inclusive and sustainable economic growth, full
            and productive employment and decent work for all.” There have been
            attempts to provide develop vocational training centres, but they
            remain ill-equipped and dont provide holistic training to youth.
          </p>
          <p>
            We propose to Fundraise, Design, Establish, and Equip rural-based
            Mundeku One Stop Shop Community Resource and Medical Center. The
            community has contributed the land to the project, which should be
            bought off at the community rate. The centre will have the following
          </p>
        </div>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/aboutus/institutional-capacity-strengthening-fundraising-and-partnership-1.jpg"
                alt="Curd Africa"
              />
            </SkeletonDisplay>
          </div>
          <div className={Styles.productCard}>
            <h3>Thematic Key Activities</h3>
            {list.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider8;

const list = [
  "Strategic Partnership",
  "Collaboration with the Government of Kenya(GoK), County Government and other stakeholders",
  "Staffing",
  "Development of Institutional Policy.",
  "Development of Resource Mobilization and Partnership Policy",
  "Development of M/E framework and data collection policy",
];
