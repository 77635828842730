const ShowCases = [
  {
    pillarId: "1",
    title: "Governance and Democracy ",
    image: "/pillar/pillar1.png",
    description:
      "Establishing and formalizing a Community-based social accountability monitoring systems within the health, water, and agriculture sectors in the targeted counties.",
    link: "/Governance and Democracy",
  },
  {
    pillarId: "2",
    title: "Economic empowerment and Climate Change",
    image: "/pillar/pillar2.png",
    description:
      "Empowering groups and individuals on wealth creation and poverty alleviation, encompassing smart climate farming, poultry farming, and pig farming.",
    link: "/Economic empowerment and Climate Change",
  },
  {
    pillarId: "3",
    title: "Social Justice, Inclusion and Child Protection",
    image: "/pillar/pillar3.png",
    description:
      "We strive to build a fair and equitable society for everyone by advocating for the rights of marginalized communities, empowering individuals of all backgrounds, and promoting a culture of diversity and acceptance. ",
    link: "/Social Justice Inclusion and Child Protection",
  },
  {
    pillarId: "4",
    title: "HIV/AIDS, Drug and Drug Abuse ",
    image: "/pillar/pillar4.png",
    description:
      "We offer extensive education and support programs on HIV/AIDS management and address drug abuse through prevention, rehabilitation, and community outreach, assisting individuals in reclaiming their lives.",
    link: "/HIV AIDS Drug and Drug Abuse",
  },
  {
    pillarId: "5",
    title: "Community Resource Center and Medical Clinic ",
    image: "/pillar/pillar5.png",
    description:
      "Our CRC and Medical Clinic offers essential services to enrich and support communities, including a youth-friendly center, vocational training, a library, a medical facility, and spaces for community engagement.",
    link: "/Community Resource Center and Medical Clinic",
  },
  {
    pillarId: "6",
    title: "Institutional Capacity Strengthening",
    image: "/pillar/pillar6.png",
    description:
      "We provide customized strategies and hands-on assistance to local institutions, empowering them to reach their objectives. Our support encompasses enhancing leadership capabilities, improving operational efficiency, and offering holistic solutions to strengthen capacity and effectiveness. ",
    link: "/Institutional Capacity Strengthening",
  },
];

export { ShowCases };
