import React from "react";
import { Routes, Route } from "react-router-dom";
import Nomatch from "./nomatch";
import Home from "./home";
import Layout from "./components/layout/Layout";
import Layout2 from "./components/layout/Layout2";
import AboutUs from "./components/generalpages/aboutus";
import ContactUs from "./components/generalpages/contactus";
import Donate from "./components/generalpages/donate";
import Careers from "./components/generalpages/careers";
import FAQs from "./components/generalpages/faqs";
import PrivacyPolicy from "./components/generalpages/privacypolicy";
import SiteMap from "./components/generalpages/sitemap";
import TermsOfService from "./components/generalpages/termsofservice";
import GovernanceandDemocracy from "./components/programmes/governanceandDemocracy";
import EconomicEmpowermentandClimateChange from "./components/programmes/economicempowermentandclimatechange";
import SocialJusticeInclusionandChildProtection from "./components/programmes/socialJusticeInclusionandchildprotection";
import HealthFocusingonHIVAIDSandDrugAbuse from "./components/programmes/hivaidsdruganddrugabuse";
import CommunityResourceCenterandMedicalClinic from "./components/programmes/communityresourcecenterandmedicalclinic";
import InstitutionalCapacityStrengthening from "./components/programmes/institutionalcapacitystrengthening";

function AppRouter() {
  return (
    <>
      <Routes>
        <>
          {/* Layout1 */}
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/Contact Us" element={<ContactUs />}></Route>
            <Route path="/Careers" element={<Careers />}></Route>
            <Route
              path="/Frequently Asked Questions"
              element={<FAQs />}
            ></Route>
            <Route path="/Privacy Policy" element={<PrivacyPolicy />}></Route>
          </Route>{" "}
        </>
        <>
          {/* Layout2 */}
          <Route path="/" element={<Layout2 />}>
            <Route path="/About Us" element={<AboutUs />} />
            <Route path="/Donate" element={<Donate />}></Route>
            <Route
              path="/Governance and Democracy"
              element={<GovernanceandDemocracy />}
            ></Route>
            <Route
              path="/Economic empowerment and Climate Change"
              element={<EconomicEmpowermentandClimateChange />}
            ></Route>
            <Route
              path="/Social Justice Inclusion and Child Protection"
              element={<SocialJusticeInclusionandChildProtection />}
            ></Route>
            <Route
              path="/HIV AIDS Drug and Drug Abuse"
              element={<HealthFocusingonHIVAIDSandDrugAbuse />}
            ></Route>
            <Route
              path="/Community Resource Center and Medical Clinic"
              element={<CommunityResourceCenterandMedicalClinic />}
            ></Route>
            <Route
              path="/Institutional Capacity Strengthening"
              element={<InstitutionalCapacityStrengthening />}
            ></Route>
            <Route path="/Site Map" element={<SiteMap />}></Route>
            <Route
              path="/Terms Of Service"
              element={<TermsOfService />}
            ></Route>
            <Route path="*" element={<Nomatch />} />
          </Route>
        </>
        <>
          {/* Layout3
          <Route path="/" element={<Layout3 />}></Route> */}
        </>
      </Routes>
    </>
  );
}

export default AppRouter;
