import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

export default function SkeletonVariant() {
  return (
    <Stack spacing={1}>
      {/* For variant="text", adjust the height via font-size */}
      <Skeleton variant="rectangular" width="100%" height={30} />

      {/* For other variants, adjust the size with `width` and `height` */}
      <div
        style={{ padding: "0rem 5rem", gap: "5rem", display: "inline-flex" }}
      >
        <Skeleton variant="circular" width={30} height={30} />
        <Skeleton variant="circular" width={30} height={30} />
        <Skeleton variant="circular" width={30} height={30} />
      </div>
      <Skeleton variant="rectangular" width="80%" height={30} />
      <Skeleton variant="rounded" width="100%" height={30} />
    </Stack>
  );
}
