import React from "react";
import Styles from "./Styles.module.css";
import SkeletonDisplay from "../../hooks/skeletonDisplay";

const Slider6 = () => {
  return (
    <div className={Styles.slider3}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <h1> Health Focusing on HIV/AIDS and Drug Abuse</h1>
            <p>
              Kenya has committed to ending AIDS as a public health threat by
              2030.The HIV epidemic in Kenya is heterogeneous, with significant
              variation in the burden of HIV across geographic areas and
              population groups. The country has made significant progress in
              reducing new HIV infections. Kenya has made progress in the HIV
              response, as evidenced by the decline in HIV prevalence among
              adults (15–49 years) in the general population, from a peak of
              about 8.7% in 2000 to 3.7% in 2022.
            </p>
          </div>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/aboutus/health-focusing-on-hiv-aids-and-drug-abuse.jpg"
                alt="Curd Africa"
              />
            </SkeletonDisplay>
          </div>
        </div>
        <div>
          <p>
            Despite notable progress, new HIV infections remain unacceptably
            high, imposing unsustainable treatment costs on the nation’s health
            system. Renewed efforts are urgently needed to reinvigorate and
            accelerate primary HIV prevention, building on lessons from previous
            prevention initiatives and other successful experiences that
            contributed to the reduction of new HIV infections.
          </p>
          <p>
            Drug abuse is one of the major social problems in Kenya with common
            and easily identifiable manifestations in public health. Half of
            drug abusers in Kenya are aged between 10-19 years with over 60%
            residing in urban areas and 21% in rural areas(UNODC). Data shows
            that the average age category for initiation of tobacco, alcohol,
            khat, cannabis, prescription drugs, cocaine and heroin was 16 – 20
            years. However, the minimum age of initiation for tobacco was 6
            years, alcohol (7 years), cannabis (8 years), khat (9 years),
            prescription drugs (8 years), heroin (18 years) and cocaine (20
            years)(NACADA 2022).
          </p>
          <p>AGREC will seek to focus on Strengthen and Expand Community-Centred HIV and Drug Prevention Services</p>
        </div>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/aboutus/health-focusing-on-hiv-aids-and-drug-abuse-1.jpg"
                alt="Curd Africa"
              />
            </SkeletonDisplay>
          </div>
          <div className={Styles.productCard}>
            <h3>Thematic Key Activities</h3>
            {list.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider6;

const list = [
  "Primary Healthcare", 
"HIV Prevention and Sexual Reproduction Health and Rights", 
"HIV Care and Treatment", 
"Mental Health and Drug abuse awareness", 
"Provision of grants", 
"Capacity strengthening of stakeholders", 
"Advocate for county-level Planning and budgeting.",
];
