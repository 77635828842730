import React from "react";
import Styles from "./Styles.module.css";

import { Zoom } from "react-awesome-reveal";
import SkeletonDisplay from "../../hooks/skeletonDisplay";

const Slider3 = () => {
  return (
    <div className={Styles.slider3}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <Zoom>
              <h1>Governance and Democracy</h1>
              <p>
                Kenya promulgated a new constitution in August 2010, that
                created enhanced participatory governance through both national
                and county government. A major governance milestone was the
                institutionalization of more decentralized governance structures
                of the 47 county government
                <p>
                  Vision 2030 Kenya’s development blueprint covering the period
                  2008 to 2030 envisions a democratic political system that is
                  issue-based, people-centered, result-oriented and accountable
                  to the public. To quote the vision, “A people-centred vision
                  is one that is responsive to the needs and the rights of
                  citizens, whose participation in all public policies and
                  resource allocation process is both fully appreciated and
                  enabled. An accountable system is open, and transparent and
                  permits the free flow of information, and in which the leaders
                  are accountable to citizens.
                </p>
              </p>
            </Zoom>
          </div>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/aboutus/governance-and-democracy-curd.jpg"
                alt="Curd Africa"
              />
            </SkeletonDisplay>
          </div>
        </div>
        <div>
          <p>
            The vision suggests devolution in policy making, public resource
            management and revenue sharing through selected devolved funds.
            Among its guiding principles are equality of citizens where men and
            women are treated equally, The Bill of Rights which will be secured
            and guaranteed irrespective of gender, disability or any other
            status and public participation in governance. This vision provides
            citizens with the right to participate directly and indirectly
            through representatives chosen in free and fair elections in
            government affairs. It gives public authorities the duty to promote
            individual and community participation in activities of society and
            to influence decision-making affecting them. Under this Thematic
            Pillar CURD will undertake the following key activities.
          </p>
        </div>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/aboutus/governance-and-democracy-curd-africa.jpg"
                alt="Curd Africa"
              />
            </SkeletonDisplay>
          </div>
          <div className={Styles.productCard}>
            <h3>Thematic Key Activities</h3>
            {list.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider3;

const list = [
  "County Budgeting and Planning Process",
  "Public Participation and Social Accountability",
  "Voter mobilization and Registration, Political Parties Participation and Strengthening",
  "Election and Election Observation",
  "Civil Society Organisation and Grants",
  "Policy and Legislation development",
];
