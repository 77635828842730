import React from "react";
import Styles from "./Styles.module.css";

import SkeletonDisplay from "../hooks/skeletonDisplay";

function Header() {
  return (
    <div>
      <div className={Styles.slider1}>
        <div className={Styles.mainContainer}>
          <SkeletonDisplay>
            <img src="/aboutus/aboutus.png" alt="Curd Africa" />
          </SkeletonDisplay>
          <button>Empowered and Poverty Free Communities</button>
        </div>
      </div>
      
    </div>
  );
}

export default Header;
